import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';

const IntervencoesLiterariasPage = ({data, ...props}) => {
  const pageTitle = "Intervenções Literárias";
  const keywords = "Intervenções Literárias, Leituras no Litoral";
  const description = "Intervenções Literárias do projeto Leituras no Litoral";

  const hasEvents = false;

  const events = () => {
    return (
      <div>
        {
          categoryDescription()
        }

        <div className='row u-margin-top'>
          {
            data.allMdx.nodes.map((node) => {
              const image = getImage(node.frontmatter.hero_image);
              const imageAlt = node.frontmatter.hero_image_alt;
              const to = `/intervencoes-literarias/${node.slug}`;
              const title = node.frontmatter.title;
              const date = node.frontmatter.date;
              const time = node.frontmatter.time;
              const location = node.frontmatter.location;
              const classification = node.frontmatter.classification;

              return (
                <div className='col-xs-12 col-sm-6' key={node.id}>
                  <article className='event-card'>
                    <Link
                      to={to}
                      title={title}
                    >
                      <div className='event-card__img'>
                        <GatsbyImage
                          image={image}
                          alt={imageAlt}
                          title={imageAlt}
                        />
                      </div>

                      <div className='event-card__body'>
                        <h2>
                          {title}
                        </h2>

                        <ul className='u-reset-list'>
                          <li><strong>Data:</strong> {date}</li>            
                          <li><strong>Horário:</strong> {time}</li>            
                          <li><strong>Local:</strong> {location}</li>
                          <li><strong>Classificação indicativa:</strong> {classification}</li>
                        </ul>

                      </div>
                    </Link>
                  </article>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const categoryDescription = () => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Sobre</h2>

              <p>Estas ações serão conduzidas pelos artistas <strong>Cristiano Nagel</strong> e <strong>Lilyan de Souza</strong> com a proposta de leitura de textos curtos para as pessoas que estiverem nas praias durante os dias e horários agendados previamente.</p>

              <p>Nestas ações se estabelece uma <strong>relação de troca entre artistas e participantes através da leitura de um texto literário</strong> e de uma conversa informal e subjetiva visando uma trajetória de aproximação prazerosa entre literatura e leitores.</p>
            </div>
          </div>

           <div className="wave wave--left wave--2"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Cristiano Nagel</h2>

              <p>Bacharel em Artes Cênicas pela FAP/Unespar (2012) e especialista em Narrativas Visuais pela UTFPR (2017).</p>

              <p>Ator, produtor cultural, mediador de leituras e contador de histórias.</p>

              <p>
                Proponente de diversos projetos culturais, tais como: “Doces ou travessuras”, “E se fosse assim?”, “De Malas Prontas: Histórias Polonesas”, “Leituras Urbanas: Literatura nas Ruas da Cidadania”, “Minha Biblioteca Viva” e “Posso ler pra você?”.
                Proprietário da Gato Preto Produções.
              </p>
            </div>
          </div>

          <div className="wave wave--left wave--1"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Lilyan de Souza</h2>

              <p>Jornalista, atriz, produtora cultural, diretora teatral, contadora de histórias, mediadora de leitura e escritora. Graduada em Comunicação Social – Jornalismo pela UNIVEL, em 2006 e em Artes Cênicas – Direção Teatral pela UNESPAR/FAP, em 2012.</p>
              
              <p>Fundadora da Inominável Companhia de Teatro.</p>

              <p>Atua na área do incentivo à leitura desde 2006, trabalhando em importantes projetos junto à SECC, FCC, SESC, SESI, Prefeituras e demais instituições de diversos municípios do Paraná e Santa Catarina. </p>

              <p>É autora das obras: “Pequenas Delicadezas Cotidianas” e “A Menina dos Sonhos”, publicadas pela Editora Inverso, em 2017. “O que é que tem no sótão?” e “Neli, do tamanho de um enorme coração”, publicadas pela editora Urutau através do selo Tádesol, Tádelua, em 2022 e 2023, respectivamente.</p>
            </div>
          </div>

          <div className="wave wave--left wave--3"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Intervenções literárias</h2>

              <ul className='u-reset-list'>
                <li><strong>Data:</strong> 01/12 à 03/12</li>            
                <li><strong>Local:</strong> Praias de Pontal do Paraná</li>
              </ul>

              <div className='u-margin-top'>
                <ul className='u-reset-list'>
                  <li><strong>Data:</strong> 08/12 à 10/12</li>            
                  <li><strong>Local:</strong> Praias de Matinhos</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      pageTitle={pageTitle}
      keywords={keywords}
      description={description}
      {...props}
    >
      {
        hasEvents ? events() : categoryDescription()
      }
    </Layout>
  )
};

// export const query = graphql`
//   query {
//     allMdx(
//       sort: {fields: frontmatter___date, order: ASC}
//       filter: {fileAbsolutePath: {regex: "/(intervencoes-literarias)/"}}
//     ) {
//       nodes {
//         frontmatter {
//           title
//           time
//           location
//           classification
//           date(formatString: "DD MMMM, YYYY", locale: "PT-BR")
//           hero_image {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 700
//                 aspectRatio: 1.33
//                 transformOptions: {
//                   fit: COVER
//                   cropFocus: ATTENTION
//                 }
//               )
//             }
//           }
//           hero_image_alt
//         }
//         id
//         slug
//       }
//     }
//   }
// `;

export default IntervencoesLiterariasPage;